"use client";

import { useState, useEffect } from "react";
import { usePathname } from "next/navigation";
import { GiHamburgerMenu } from "react-icons/gi";

import { Logo } from "./Logo";
import { Nav } from "./Nav";
import { Search } from "./Search";

import styles from "./header.module.scss";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [withH1, setWithH1] = useState(true);
  const pathname = usePathname();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setWithH1(pathname === "/");
  }, [pathname, withH1]);

  return (
    <header className={styles.header}>
      <section className="container">
        <div className={styles.content}>
          <Logo withH1={withH1} />

          <div className={styles.toolbar}>
            <Search />
            <Nav toggle={isOpen} handleToggle={handleToggle} />
            <button
              type="button"
              className={styles.toggleMenu}
              onClick={handleToggle}
              title="Abrir menu mobile"
            >
              <GiHamburgerMenu />
            </button>
          </div>
        </div>
      </section>
    </header>
  );
};

export { Header };
