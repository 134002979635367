import { useRouter } from "next/navigation";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";

import { api } from "../../../../services/api";
import { useSearch } from "../../../context/SearchContext";

import styles from "./search.module.scss";
import { useState } from "react";

const Search = (): JSX.Element => {
  const router = useRouter();
  const [query, setQuery] = useState("");
  const { setResults, isLoading, setIsLoading } = useSearch();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (query.trim() !== "") {
      try {
        const response = await api.get("/api/search", {
          params: {
            query,
          },
        });
        const { posts } = response.data;

        const encodedTerms = encodeURI(query);

        setResults(posts, query);

        setIsLoading(false);
        router.push(`/pesquisa?termo=${encodedTerms}`);
      } catch (error) {
        toast.error("Um erro ocorreu ao realizar a busca.");
      }
    } else {
      toast.error("Digite algo para pesquisar..");
    }

    setIsLoading(false);
    setQuery("");
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        <input
          type="text"
          name="pesquisa"
          placeholder="Pesquisar..."
          onChange={(e) => setQuery(e.target.value)}
          required
          disabled={isLoading}
          value={query}
        />
        <button type="submit" title="Pesquisar" disabled={isLoading}>
          <FaSearch />
        </button>
      </form>
    </>
  );
};

export { Search };
