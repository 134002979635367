"use client";
import { usePathname } from "next/navigation";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

import { IPost } from "src/interfaces/IPost";

interface SearchContextProps {
  query: string | null;
  results: IPost[] | null; // Assumindo que IPost é o tipo do resultado
  isLoading: boolean;
  setQuery: (query: string) => void;
  setResults: (newResults: IPost[], newQuery: string) => void; // Função com dois parâmetros
  setIsLoading: (isLoading: boolean) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export function SearchProvider({ children }: { children: ReactNode }) {
  const pathname = usePathname();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<IPost[] | null>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Efeito para carregar resultados do localStorage (caso existam)
  useEffect(() => {
    setIsLoading(true);
    const savedResults = localStorage.getItem("searchResults");
    const savedQuery = localStorage.getItem("searchQuery");
    if (savedResults && savedQuery) {
      try {
        setResults(JSON.parse(savedResults));
      } catch (error) {
        setResults([]);
      }
      setQuery(savedQuery);
    }
    setIsLoading(false);
  }, []);

  // Efeito para limpar o localStorage ao sair da rota "/pesquisa"
  useEffect(() => {
    const handleRouteChange = (url: string | null) => {
      if (url && !url.startsWith("/pesquisa")) {
        localStorage.removeItem("searchResults");
        localStorage.removeItem("searchQuery");
        setQuery("");
      }
    };

    handleRouteChange(pathname);
  }, [pathname]);

  const updateResults = (newResults: IPost[], newQuery: string) => {
    setResults(newResults);
    setQuery(newQuery);
    localStorage.setItem("searchResults", JSON.stringify(newResults));
    localStorage.setItem("searchQuery", newQuery);
  };

  return (
    <SearchContext.Provider
      value={{
        query,
        results,
        isLoading,
        setQuery,
        setResults: updateResults,
        setIsLoading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
}
