import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { BsThreads } from "react-icons/bs";
import { IconType } from "react-icons";

interface ILinks {
  title: string;
  url: string;
  Icon: IconType;
}

export const Links: ILinks[] = [
  {
    title: "Facebook",
    url: "https://www.facebook.com/adevindev.xyz",
    Icon: FaFacebookF,
  },
  {
    title: "Instagram",
    url: "https://www.instagram.com/adevindev/",
    Icon: FaInstagram,
  },
  {
    title: "Threads",
    url: "https://www.threads.net/@adevindev",
    Icon: BsThreads,
  },
  {
    title: "Twitter",
    url: "https://twitter.com/adevindev",
    Icon: FaTwitter,
  },
];
