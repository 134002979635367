import { usePathname } from "next/navigation";

import Link from "next/link";
import { ComponentProps } from "react";

interface INavItem extends ComponentProps<"li"> {
  link: string;
  label: string;
}

const NavItem = ({ link, label, ...props }: INavItem): JSX.Element => {
  const pathname = usePathname();

  return (
    <li {...props}>
      <Link
        href={link}
        className={`linkHover${pathname === link ? " current" : ""}`}
      >
        {label}
      </Link>
    </li>
  );
};

export { NavItem };
