import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Fira_Sans\",\"arguments\":[{\"weight\":[\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--headingFont\"}],\"variableName\":\"fira\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Merriweather\",\"arguments\":[{\"weight\":[\"300\",\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--generalFont\"}],\"variableName\":\"merriweather\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/logos/garra.digital.ico");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/logos/google-safe.png");
;
import(/* webpackMode: "eager", webpackExports: ["Cookies"] */ "/vercel/path0/src/components/Footer/Cookies/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/Menu/footer.menu.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/Copy/copy.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/UI/Layout/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SocialLinks"] */ "/vercel/path0/src/components/UI/SocialLinks/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchProvider"] */ "/vercel/path0/src/context/SearchContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
