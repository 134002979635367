import Image from "next/image";
import Link from "next/link";

import styles from "./logo.module.scss";

import logoImage from "../../../../public/assets/logos/adid.png";

interface ILogo {
  withH1?: boolean | undefined;
}

const Logo = ({ withH1 = true }: ILogo): JSX.Element => {
  return (
    <>
      {withH1 ? (
        <h1>
          <Link href="/" aria-label="Ir para Home" className={styles.logoLink}>
            <Image
              src={logoImage}
              alt="Logo A dev in dev"
              width={32}
              height={32}
            />
            <span>A dev in dev</span>
          </Link>
        </h1>
      ) : (
        <Link href="/" aria-label="Ir para Home" className={styles.logoLink}>
          <Image
            src={logoImage}
            alt="Logo A dev in dev"
            width={32}
            height={32}
          />
          <span>A dev in dev</span>
        </Link>
      )}
    </>
  );
};

export { Logo };
