"use client";

import { sendGAEvent } from "@next/third-parties/google";
import { Links } from "./Links";
import styles from "./sociallinks.module.scss";

interface ISocialLinks {
  customClass?: string;
}

const SocialLinks = ({ customClass }: ISocialLinks): JSX.Element => {
  return (
    <div className={customClass || styles.socialWrapper}>
      {Links.map(({ Icon, title, url }) => (
        <a
          key={title}
          href={url}
          title={title}
          target="_blank"
          rel="noreferrer"
          className={`linkHover ${styles.socialLink}`}
          onClick={() =>
            sendGAEvent({ event: "Clique Rede Social", value: title })
          }
        >
          <Icon />
        </a>
      ))}
    </div>
  );
};

export { SocialLinks };
